import React, {Suspense} from 'react';
import {Helmet} from 'react-helmet';
import {Experiences, Projects, Services, Skills} from "./PagesLazyLoad";
import {ScrollToTop} from "@florian-cassagne/simple-toolbox";
import {APP_AUTHOR_NAME_FULL} from "../constants/AppConstants";
import {useTranslation} from "react-i18next";
import LoadingSpinner from "../components/_AppElements/LoadingSpinner";

function Homepage() {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${APP_AUTHOR_NAME_FULL} : ${t('page.homepage.meta.title')}`}</title>
        <meta name="description" content={t('page.homepage.meta.description')}/>
      </Helmet>

      <ScrollToTop/>
      <Suspense fallback={<LoadingSpinner/>}>
        <Services isOverviewMode={true}/>
      </Suspense>
      <Suspense fallback={<LoadingSpinner/>}>
        <Experiences isOverviewMode={true}/>
      </Suspense>
      <Suspense fallback={<LoadingSpinner/>}>
        <Projects isOverviewMode={true}/>
      </Suspense>
      <Suspense fallback={<LoadingSpinner/>}>
        <Skills isOverviewMode={true}/>
      </Suspense>
    </>
  );
}


export default Homepage;
